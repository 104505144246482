import React, { useState, useEffect } from "react";

import { useFetch } from '../services/useFetch';
import { Panel} from 'react-bootstrap';
import {PackingGraphs4} from "./PackingGraphs";
import CsvDownloadButton from 'react-json-to-csv'

import {LineGraphs4} from "./LineGraphs";
import { serverURL } from "../services/api";
import { getGraphSeries} from "../services/util"; 


export default function SalesCustomer(props) {
  const contact = props.contactName;
  const [data3] = useFetch(serverURL + "bill/sales/" + contact + "/2021-01-01/2021-12-31");
  const [data4] = useFetch(serverURL + "bill/sales/" + contact + "/2022-01-01/2022-12-31");
  const [data5] = useFetch(serverURL + "bill/sales/" + contact + "/2023-01-01/2023-12-31");
  const [data6] = useFetch(serverURL + "bill/sales/" + contact+ "/2024-01-01/2024-12-31");
  const [data7] = useFetch(serverURL + "bill/sales/" + contact + "/2025-01-01/2025-12-31");


  if( !data4 || !data5 || !data7 || !data3 || !data6){
    return (
      <Panel className="foulger" collapsible defaultExpanded header={'Sales ' + contact}>
       <div className="loader">Loading...</div>
      </Panel>
    )
  }
  else{
    const costSeries2 = getGraphSeries(data3.monthList); 
    const costSeries3 = getGraphSeries(data4.monthList); 
    const costSeries4 = getGraphSeries(data5.monthList); 
    const costSeries5 = getGraphSeries(data6.monthList); 
    const costSeries6 = getGraphSeries(data7.monthList); 


  return (
    <Panel className="foulger" collapsible defaultExpanded header={data4.name}>
    <div>{PackingGraphs4( costSeries2, costSeries3, costSeries4, costSeries5, costSeries6)}</div>

          <div>Sales 2021: {data3.totalRatio}<CsvDownloadButton delimiter=","
        filename={contact + '2021'} 
        data={data3.monthList}
        style={{
          borderRadius:"6px",
          display:"inline-block",
          cursor:"pointer","color":"#ffffff",
          fontSize:"15px",
          fontWeight:"bold",
          padding:"6px 24px",
          textDecoration:"none",
          textShadow:"0px 1px 0px #9b14b3", 
          margin:"10px 0px 0px 50px",
          color: "black"
        }}
      >Export 2121 Data</CsvDownloadButton>
        </div>
          <div>Sales 2022: {data4.totalRatio}<CsvDownloadButton delimiter=","
        filename={contact + '2022'} 
        data={data4.monthList}
        style={{
              borderRadius:"6px",
              display:"inline-block",
              cursor:"pointer","color":"#ffffff",
              fontSize:"15px",
              fontWeight:"bold",
              padding:"6px 24px",
              textDecoration:"none",
              textShadow:"0px 1px 0px #9b14b3", 
              margin:"10px 0px 0px 50px",
              color: "black"

        }}
      >Export 2022 Data</CsvDownloadButton>
        </div>
        <div>Sales 2023: {data5.totalRatio}<CsvDownloadButton delimiter=","
        filename={contact + '2023'} 
        data={data5.monthList}
        style={{
          borderRadius:"6px",
          display:"inline-block",
          cursor:"pointer","color":"#ffffff",
          fontSize:"15px",
          fontWeight:"bold",
          padding:"6px 24px",
          textDecoration:"none",
          textShadow:"0px 1px 0px #9b14b3", 
          margin:"10px 0px 0px 50px",
          color: "black"

        }}
      >Export 2023 Data</CsvDownloadButton>
        </div>
        <div>Sales 2024: {data6.totalRatio}<CsvDownloadButton delimiter=","
        filename={contact + '2024'} 
        data={data6.monthList}
        style={{
          borderRadius:"6px",
          display:"inline-block",
          cursor:"pointer","color":"#ffffff",
          fontSize:"15px",
          fontWeight:"bold",
          padding:"6px 24px",
          textDecoration:"none",
          textShadow:"0px 1px 0px #9b14b3", 
          margin:"10px 0px 0px 50px",
          color: "black"
        }}
      >Export 2024 Data</CsvDownloadButton>
      </div>
      <div>Sales 2025: {data7.totalRatio}<CsvDownloadButton delimiter=","
        filename={contact + '2020'} 
        data={data7.monthList}
        style={{
          borderRadius:"6px",
          display:"inline-block",
          cursor:"pointer","color":"#ffffff",
          fontSize:"15px",
          fontWeight:"bold",
          padding:"6px 24px",
          textDecoration:"none",
          margin:"10px 0px 0px 50px",
          color: "black"
        }}
      >Export 2025 Data</CsvDownloadButton>
        </div>

    </Panel>

  );
}
}
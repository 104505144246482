import React from 'react';
import SalesTopX from './SalesTopX';
import { Panel} from 'react-bootstrap';
import DateFnsFormat from 'date-fns/format';
import TotalSales from './TotalSales';
import WareHouseSales from './WareHouseSales';


class CustomerInsights extends React.Component {
  constructor(props) {
    super(props)
  }


  render() {
    
    const groupURL = "bill/sales/groups/2024-01-01/" + DateFnsFormat(new Date(), 'yyyy-MM-dd') + "/30"; 
    const  garageUrl = "bill/sales/warehouse/Garage/2024-01-01/" + DateFnsFormat(new Date(), 'yyyy-MM-dd') + "/100"; 
   
    return(
        <Panel className="CustomerInsights 2024" collapsible defaultExpanded header="Customer Insights">
        
          <TotalSales id="all" />
          <TotalSales id="notbrakebidfood" />
          <SalesTopX url={groupURL} title="Top Customers" />
           <SalesTopX url={garageUrl} title="Garage Customers" /> 
           <WareHouseSales contactName="all" title="All Customers" months="6"/>
           <WareHouseSales contactName="notbrakebidfood" title="Not Brakes and Bidfood" months="6"/>
    
        </Panel>
      )
    }
  
}

export default CustomerInsights;
